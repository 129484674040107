.FormButton {
  margin-top: 100px;
}

.btn {
  width: 100%;
	padding: 20px;
	border-width: 0;
	border-radius: 10px;
  color: var(--blanco);
  font-weight: bold;
	/* box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1); */
}

.primary {
  background-color: var(--violeta-godbt);
}
.primary:hover {
  color: var(--blanco);
  background-color: var(--bedekken-violeta)
}

.alternative {
  background-color: var(--gris-medio);
}