.PatientImage {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: var(--gris-medio);
}

.PatientImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PatientImage span {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: var(--blanco);
}