.GoBackHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
}

.GoBackHeader-back {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);
  background-color: var(--blanco);
}

.GoBackHeader-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.GoBackHeader-div-helper {
  height: 38px;
  width: 38px;
}