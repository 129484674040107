.Request-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;
}

.Request-id {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.Request-status {
  display: flex;
}

.Request-status {
  justify-content: space-between;
}

.Request-status button {
  width: 93px;
  padding: 0;
  font-weight: 600;
  color: var(--blanco);
  background-color: var(--violeta-godbt);
  border: none;
  border-radius: 10px;
}
.Request-status button:hover {
  background-color: var(--bedekken-violeta);
}