.RequestCard {
  /* height: 196px; */
  display: flex;
  flex-direction: column;
  padding: 12px 19px 24px 19px;
  border-radius: 10px;
  background-color: var(--blanco);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
}

.RequestCard.no-header {
  padding-top: 24px;
}

.RequestCard-content--child:first-child {
  margin-top: 28px;
}

.RequestCard-content--child {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.RequestCard-content--child {
  word-wrap: break-word;
}

.RequestCard-content--child p:first-child {
  color: var(--gris-medio);
}

.RequestCard-content--child p:last-child {
  font-weight: 600;
}

.RequestCard-content--child:not(.divider):not(:first-child) {
  margin-top: 18px;
}

.RequestCard-content--child.divider:not(:last-child) {
  margin-bottom: 18px;
  border-bottom: 1px solid var(--linea-gris);
}

.RequestCard-content--child.divider:not(:last-child) p {
  padding-bottom: 18px;
}

.RequestCard-content--child.no-header:first-child {
  margin-top: 0;
}

.RequestCard-detail {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px;
  padding-top: 2px;
}

.RequestCard-detail button {
  margin-right: 6.25px;
  padding: 0;
  border: none;
  font-weight: 600;
  color: var(--violeta-godbt);
  background-color: var(--blanco);
}

.RequestCard-detail button:hover {
  background-color: var(--bedekken-violeta);
}