.FormGroup {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.FormGroup-label {
  display: flex;
  align-items: center;
  color: var(--gris-medio);
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	font-size: 15px;
}

.FormGroup-label label {
  margin-left: 7px;
}