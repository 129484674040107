.PatientAction {
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 38px 22px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
  background-color: var(--blanco);
}
.PatientAction:hover {
  border: 2px solid var(--violeta-godbt);
  /* border-color: var(--azul-oscuro); */
}
.not-last {
  margin-bottom: 23px;
}

.PatientAction div {
  height: 40px;
  display: flex;
  /* align-items: center; */
}

div .PatientAction-icon {
  height: 33px;
  width: 33px;
  margin-right: 9px;
}

div .PatientAction-name {
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 10px;
}