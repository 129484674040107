.RequestFormFileButton {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  color: var(--blanco);
  background-color: var(--gris-medio);
}
.RequestFormFileButton:hover {
  background-color: #707070;
}

.RequestFormFileButton img {
  margin-right: 8px;
}