.RequestStatusPage {
  min-height: 100vh;
  padding: 39px 26px;
  background-color: var(--background-gris-claro);
}

.RequestStatusPage-go-back {
  height: 38px;
  width: 38px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);
  background-color: var(--blanco);
}

.RequestStatusPage-header {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.RequestStatusPage-header div img {
  margin-bottom: 11px;
}

.RequestStatusPage-header div p {
  font-size: 1.1rem;
}

.RequestStatusPage-header--title {
  margin-bottom: 4px;
  font-weight: 600;
}