.Login-container, .Login-header, .Login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--background-disabled);
}

.Login-header {
  width: 40%;
}

.Login-body {
  width: 60%;
  background-color: var(--blanco);
}

.Login-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 80%;
}

.Login-link, .Login-link--centered {
  display: flex;
  margin-top: 20px;
  color: var(--violeta-godbt);
}
.Login-link a {
  color: var(--violeta-godbt);
}

.Login-link--centered {
  justify-content: center;
}

@media screen and (max-width: 930px) {
  .Login-container {
    flex-direction: column;
  }

  .Login-header, .Login-body, .Login-content {
    width: 100%;
    height: 25%;
  }

  .Login-body {
    height: 75%;
    padding: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .Login-content {
    justify-content: flex-start;
    height: 100%;
  }
}