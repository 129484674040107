.RequestDetail-container {
  min-height: 100vh;
  padding: 39px 26px;
  background-color: var(--background-gris-claro);
}

.RequestDetail-status {
  margin-bottom: 20px;
}

.RequestDetail-comments--header,
.RequestDetail-comments--header-btn {
  display: flex;
  align-items: center;
}

.RequestDetail-comments--header {
  justify-content: space-between;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--text-black);
  font-weight: 600;
}

.RequestDetail-comments--header-title {
  font-size: 1.1rem;
}

.RequestDetail-comments--header-btn p {
  margin-right: 10px;
  color: var(--violeta-godbt);
}

.RequestDetail-comments--header-btn button {
  height: 32px;
  width: 40px;
  border: none;
  border-radius: 8px;
  background-color: var(--violeta-godbt);
  cursor: pointer;
}
.RequestDetail-comments--header-btn button:hover {
  background-color: var(--bedekken-violeta);
}