.RequestComment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.RequestComment:not(:last-child) {
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--text-black);
}

.RequestComment div {
  width: 60%;
}

.RequestComment-title {
  padding-bottom: 8px;
  color: var(--violeta-godbt);
}

.RequestComment-message {
  padding-bottom: 5px;
  color: var(--text-black);
}

.RequestComment-download {
  height: 34px;
  width: 42px;
  /* padding: 10px 16px; */
  border: none;
  border-radius: 6px;
  background-color: var(--gris-medio);
  cursor: pointer;
}
.RequestComment-download:hover {
  background-color: var(--text-black);
}