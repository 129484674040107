.PatientRequest-container {
  height: 100vh;
  padding: 39px 26px;
  background-color: var(--background-gris-claro);
}

.PatientRequest-main {
  height: 90%;
}

.PatientRequest-form{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PatientRequest-control {
  padding: 15px;
  border-radius: 10px;
  border: none;
  border: 1px solid var(--linea-gris);
  outline: none;
  font-weight: bold;
}
/* .PatientRequest-control > option {
  //
} */

#request-date:disabled {
  color: var(--negro);
  background-color: var(--background-disabled);
}

#request-text {
  font-weight: normal;
}

#request-file {
  display: none;
}