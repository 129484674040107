.RequestStatus {
  height: 44px;
  width: 156px;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border: 1px solid var(--linea-gris);
  border-radius: 10px;
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.03));
}
.RequestStatus p {
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 15px;
}