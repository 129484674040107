.Patient-container {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  padding: 52px 24px 43px 24px;
  background-color: var(--background-gris-claro);
}

.Patient-header {
  display: flex;
  justify-content: center;
}
.Patient-header img {
  height: 102px;
  width: 159px;
  object-fit: cover;
}

.Patient-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.Patient-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.Patient-data--name {
  font-size: 1.2rem;
  font-weight: 600;
  /* text-align: center; */
}

.Patient-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Patient-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Patient-logout {
  display: flex;
  align-items: center;
}
.Patient-logout img {
  margin-right: 10px;
}
.Patient-logout p {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--gris-medio);
}